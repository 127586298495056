import React, {useEffect, useState} from 'react'
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";


import { baseLink, packageExtraType, cabanaPackageGroupTypeCode, transportPackageGroupTypeCode} from '../../constants/DataPublic'
import { useGlobalState } from '../../global'

export const PackageCardD = (props) => {

    const {packageCard} = props 

    const [routePath, setRoutePath] = useGlobalState('route');
    const [picturePath, setPicturePath] = useState()
    const [netPrice, setNetPrice] = useState(0)

    useEffect(()=>{
        if (packageCard.PackagePictureLists.length > 0){
            setPicturePath(packageCard.PackagePictureLists[1].PictureUrl)
        }

        if (packageCard.IsDiscount){
          let netP = packageCard.PackagePrice - packageCard.DiscountPrice
          setNetPrice(netP)
        } else setNetPrice(packageCard.PackagePrice)

    },[])  

    const addToCart = (item, isEdit) =>{
      props.onAddToCart(item,isEdit)
    }

    // ภาษา
    const { t } = useTranslation();
    
    return (
      <div className="extra-landingpage" style={{width:'300px'}}>
        <div className="col-lg-12 mb-4 d-flex align-items-stretch">
          <div className="card card-home h-100">
            <img src={picturePath} className="card-img-top" alt="Card Image" />
            <div className="card-body d-flex flex-column box-dec-extra">
              <h5 className="card-title font-24 mb-1 lh-1">{packageCard.PackageName}</h5>
              <p className="card-text mb-1 font-11 color-grey weight-500"><Markup content={packageCard.PackageDescription}/></p>
            </div>
            <div className="home-card-footer">
              <div className="text-center">
                <a href={baseLink+ routePath +"/ticket/"+packageCard.PackageCode+"/"+packageCard.StepId}>
                  <button type="button" className="btn btn-main mt-auto mx-auto align-self-start mb-2 "
                    onClick={() => {
                      if (packageCard.PackageGroupTypeCode != cabanaPackageGroupTypeCode && packageCard.PackageGroupTypeCode != transportPackageGroupTypeCode && !packageCard.IsSaleByItem){
                        addToCart(
                        {
                            PackageCode: packageCard.PackageCode,
                            PackageName: packageCard.PackageName,
                            ItemCode: "",
                            ItemName: "",
                            Qty: 1,
                            Tax: 0,
                            ItemPrice: netPrice,
                            ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
                            PriceName: packageCard.PriceName,
                            PriceUnit: '',
                            TotalPrice: netPrice,
                            VisitDate: null,
                            VisitTime: '',
                            VisitLocation: '',
                            step: packageCard.StepId,
                            status: 'normal',
                            IsPromoDiscount: packageCard.IsDiscount,
                          }, false
                        ) 
                        props.getPackageSubTypeCode("")
                      } else {
                        props.getPackageSubTypeCode(packageCard.PackageSubTypeCode)
                      }
                  }}
                  >{t('buy_now')}</button>	
                  </a>
                <a href={baseLink+ routePath + "/ticket/"+packageCard.PackageCode+"/"+packageCard.StepId}><button type="button" className="btn btn-border mt-auto mx-auto  ">Details</button>	</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
